import React, { useEffect, useState, useRef } from "react";

import {
    Container,
    Typography,
    Grid,
    Button,
    Box,
    useTheme,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    DialogTitle, DialogActions, DialogContent, Link, Dialog, TextField
} from "@mui/material";
import {
    useGetBankAccountsQuery,
    useGetProfileQuery,
    useLazyPlayerGetOtpQuery,
    useLazyPlayerVerifyOtpQuery
} from "../../features/api/userApiSlice";
import * as Constant from "../../features/constant";
import {useNavigate} from "react-router-dom";
import {setAlert} from "../../features/slices/alertSlice";
import {t} from "i18next";
import {useDispatch} from "react-redux";



const SecurityCenter = ({ usePlayerBanksMutation,progress }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [IDVerificationOpen, setIDVerificationOpen] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false);
    const [phoneOpen, setPhoneOpen] = useState(false);
    const [emailCode, setEmailCode] = useState(['', '', '', '', '', '']);
    const [phoneCode, setPhoneCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const PATH = process.env.PUBLIC_URL;
    const [seconds, setSeconds] = useState(null);

    const focusNextInput = (index) => {
        if (inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };

    const focusPreviousInput = (index) => {
        if (inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };


    const handleClick = (item) => {
        // Handle click action for the item
        console.log(`Clicked ${item}`);
    }

    const handleIDVerificationOpen = () => {
        setIDVerificationOpen(true);
    };

    const handleIDVerificationClose = () => {
        setIDVerificationOpen(false);
    };

    const canvasRef = useRef(null);

    const {
        data: user,
        isLoading: isUserLoading,
        isError: isUserError,
        isSuccess: isUserSuccess,
        error: userError,
    } = useGetProfileQuery();

    const handleIDVerificationClick = () => {
        if (user?.result?.isIdentityVerified){
            navigate(Constant.getPath("/profileWallet"), {
                replace: true,
                state: { screen: "security center" },
            });
        }
        else if (user?.result?.isIdVerificationSubmitted) {
            setIDVerificationOpen(true);
        }
        else {
            navigate(Constant.getPath("/profileWallet"), {
                replace: true,
                state: { screen: "id verification" },
            });
        }
    };

    const handleEmailClick = () => {
        if (user?.result?.isEmailVerified){
            navigate(Constant.getPath("/profileWallet"), {
                replace: true,
                state: { screen: "security center" },
            });
        }
        else{
            handleEmailOpen(true);
        }
    };

    const handlePhoneClick = () => {
        if (user?.result?.isPhoneVerified){
            navigate(Constant.getPath("/profileWallet"), {
                replace: true,
                state: { screen: "security center" },
            });
        }
        else{
            handlePhoneOpen(true);
        }
    };


    const handlePhoneOpen = () => {
        setPhoneOpen(true);
        getOtp("MOBILE");
        setSeconds(120);
    };

    const handlePhoneClose = () => {
        setPhoneOpen(false);
        setPhoneCode(['', '', '', '', '', '']);
    };

    const handleEmailOpen = () => {
        setEmailOpen(true);
        getOtp("EMAIL");
        setSeconds(120);
    };

    const handleEmailClose = () => {
        setEmailOpen(false);
        setEmailCode(['', '', '', '', '', '']);
    };

    const handleVerifyEmail = async() => {
        // Perform email verification logic here
        let result = await verifyOtp({channel: "EMAIL", otp: emailCode.join("")});
        if (result.isSuccess) {
            handleEmailClose();
            dispatch(
                setAlert({
                    isOpen: true,
                    message: t("myaccountpage.verifyotpsuccess"),
                    variant: "success",
                })
            );
            window.location.reload();
        }
        else {
            dispatch(
                setAlert({
                    isOpen: true,
                    message: t("myaccountpage.verifyotpfailed"),
                    variant: "error",
                })
            );
        }
    };

    const handleVerifyPhone = async() => {
        // Perform phone verification logic here
        let result = await verifyOtp({channel: "MOBILE", otp: phoneCode.join("")});
        if (result.isSuccess) {
            handlePhoneClose();
            dispatch(
                setAlert({
                    isOpen: true,
                    message: t("myaccountpage.verifyotpsuccess"),
                    variant: "success",
                })
            );
            window.location.reload();
        }
        else {
            dispatch(
                setAlert({
                    isOpen: true,
                    message: t("myaccountpage.verifyotpfailed"),
                    variant: "error",
                })
            );
        }
    };

    const handleChangeEmail = (index, value) => {
        if (value.length > 1) {
            value = value.charAt(0);
        }
        const newEmailCode = [...emailCode];
        newEmailCode[index] = value;
        setEmailCode(newEmailCode);

        if (value !== '') {
            focusNextInput(index);
        } else {
            focusPreviousInput(index);
        }
    };

    const handleChangePhone = (index, value) => {
        if (value.length > 1) {
            value = value.charAt(0);
        }
        const newPhoneCode = [...phoneCode];
        newPhoneCode[index] = value;
        setPhoneCode(newPhoneCode);

        if (value !== '') {
            focusNextInput(index);
        } else {
            focusPreviousInput(index);
        }
    };

    const [
        getOtp,
        {
            data: getOtpData,
            // isFetching: isGetOtpFetching,
            isLoading: isGetOtpLoading,
            isError: isGetOtpError,
            isSuccess: isGetOtpSuccess,
            error: getOtpError,
        },
    ] = useLazyPlayerGetOtpQuery({ prefetch: true });

    const [
        verifyOtp,
        {
            data: verifyOtpData,
            // isFetching: isGetOtpFetching,
            isLoading: isVerifyOtpLoading,
            isError: isVerifyOtpError,
            isSuccess: isVerifyOtpSuccess,
            error: verifyOtpError,
        },
    ] = useLazyPlayerVerifyOtpQuery({ prefetch: true });


    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const maxSize = Math.min(canvas.width, canvas.height);
        const radius = maxSize * 0.4;
        const startAngle = (3/2) * Math.PI; // Start angle at 12 o'clock position
        const endAngle = startAngle + (75 / 100) * Math.PI * 2 ; // 25% of circle (π/2 radians)
        const lineWidth = maxSize * 0.1;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.beginPath();
        context.arc(centerX, centerY, radius, 0, Math.PI * 2, false);
        context.strokeStyle = 'lightgrey';
        context.lineWidth = lineWidth;
        context.stroke();

        context.beginPath();
        context.arc(centerX, centerY, radius, startAngle, endAngle, false);
        context.strokeStyle = '#ee2842';
        context.lineWidth = lineWidth;
        context.stroke();

        // Draw percentage text
        context.fillStyle = 'grey';
        context.font = `${maxSize * 0.2}px Arial`; // Adjust font size as needed
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(`${50}%`, centerX, centerY);
    }, [progress]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(prevSeconds => prevSeconds - 1);
            }
        }, 1000);

        // Cleanup function to clear the interval when component unmounts or when seconds reach 0
        return () => clearInterval(timer);
    }, [seconds]);

    return (
        <Container>
            <Typography fontWeight="bold" color="white">
            Security Center
            </Typography>
            <br/>
            <Grid container spacing={1} sx={{backgroundColor:'#FFFFFF',borderRadius:'5px'}}>
                <Grid item xs={12} sm={8} >
                    <Grid container spacing={2}>
                        {/* Canvas circle progress bar */}
                        <Grid item xs={6}>
                            <canvas ref={canvasRef} width={100} height={100}/>
                        </Grid>
                        {/* Column with four rows of sentences */}
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid container spacing={1} direction="column">
                                {/*<Grid item>*/}
                                {/*    <Typography*/}
                                {/*    sx={{fontSize:'16px', fontWeight:'bold'}}*/}
                                {/*    >Security Level</Typography>*/}
                                {/*</Grid>*/}
                                {/*<Grid item>*/}
                                {/*    <Typography*/}
                                {/*        sx={{fontSize: { xs: '14px', sm: '16px' }, fontWeight:'bold', color:'red'}}>thunder</Typography>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                    <Typography
                                        sx={{fontSize: { xs: '12px', sm: '14px' }, fontWeight:'bold', color:'grey'}}>Last Login IP: 192.168.0.0</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{fontSize: { xs: '12px', sm: '14px' }, fontWeight:'bold', color:'grey'}}>Last login time: 2024-04-07 13:05:41</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List>
                        <ListItem button
                                      onClick={() => {
                                      navigate(Constant.getPath("/profileWallet"), {
                                          replace: true,
                                          state: { screen: "my account" },
                                      });
                                  }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Personal Information</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px' }}>Complete Your Personal Information</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={handleIDVerificationClick}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{fontWeight: 'bold', color: 'white'}}>ID Verification</span>}
                                secondary={
                                    user?.result?.isIdentityVerified ? (
                                        <span style={{ color: 'green', fontSize: '12px' }}>Verified</span>
                                    ) : user?.result?.isIdVerificationSubmitted ? (
                                        <span style={{ color: '#FDB813', fontSize: '12px' }}>ID Verification Submitted</span>
                                    ) : (
                                        <span style={{ color: 'lightgrey', fontSize: '12px' }}>Verify Your Profile Now</span>
                                    )
                                }
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={() => {
                            navigate(Constant.getPath("/profileWallet"), {
                                replace: true,
                                state: { screen: "change password" },
                            });
                        }}>
                            <ListItemAvatar>
                                <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Change Password</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px' }}>Recommend Letter and Number Combination</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={handlePhoneClick}>
                            <ListItemAvatar>
                                <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Mobile Verification</span>}
                                secondary={
                                    user?.result?.isPhoneVerified ? (
                                        <span style={{ color: 'green', fontSize: '12px' }}>Verified</span>
                                    ):(
                                        <span style={{ color: 'lightgrey', fontSize:'12px' }}>Mobile Verification</span>
                                        )
                                    }
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={handleEmailClick}>
                            <ListItemAvatar>
                                <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Email Verification</span>}
                                secondary={
                                    user?.result?.isEmailVerified ? (
                                        <span style={{ color: 'green', fontSize: '12px' }}>Verified</span>
                                    ):(
                                        <span style={{ color: 'lightgrey', fontSize:'12px' }}>Email Verification</span>
                                    )
                                }
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                    </List>
                </Grid>
            </Grid>
            {/*ID Verification Dialog*/}
            <Dialog open={IDVerificationOpen} onClose={handleIDVerificationOpen} BackdropProps={{invisible: true}}>
                <DialogTitle sx={{padding: 0}}>
                    <DialogActions>
                        <Button onClick={handleIDVerificationClose}>X</Button>
                    </DialogActions>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            color: 'white',
                        }}
                    >
                        In-Progress</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}
                    >
                        You have submitted your ID Verification. Please wait for approval. </Typography>
                    <br/>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}>
                        Experiencing any issues?{' '}
                        <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                            Contact Customer Support
                        </Link>
                    </Typography>
                    <br/>
                </DialogContent>
            </Dialog>
            {/*Mobile Dialog*/}
            <Dialog open={phoneOpen} onClose={handlePhoneClose} BackdropProps={{invisible: true}}>
                <DialogTitle sx={{padding: 0}}>
                    <DialogActions>
                        <Button onClick={handlePhoneClose}>X</Button>
                    </DialogActions>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            color: 'white',
                        }}
                    >
                        Verify Mobile Number</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}
                    >
                        A 6-digit PIN number has been sent to your phone.</Typography>

                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: 'white',
                            paddingTop: '5px'
                        }}
                    >
                        ********{user?.result?.mobileNumber?.substring(user?.result?.mobileNumber?.length - 4, user?.result?.mobileNumber?.length - 1)}</Typography>
                    <br/>
                    <Grid container spacing={1} alignItems="center">
                        {[...Array(6)].map((_, index) => (
                            <Grid item key={index}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                    value={phoneCode[index]}
                                    onChange={(e) => handleChangePhone(index, e.target.value)}
                                    inputProps={{
                                        maxLength: 1,
                                        style: {textAlign: 'center', width: '10px', height: '15px'}
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <br/>
                    <DialogActions sx={{justifyContent: 'center'}}>
                        <Button variant="contained" color="error" onClick={handleVerifyPhone}>
                            VERIFY
                        </Button>
                    </DialogActions>
                    <br/>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}>
                        Experiencing any issues?{' '}
                        <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                            Contact Customer Support
                        </Link>
                    </Typography>
                    <br/>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}
                    >
                        Didn't receive the PIN number?{' '}
                        <Typography
                            component="span"
                            sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                            onClick={seconds === 0 ? handlePhoneOpen : null}
                        >
                            Resend code ({seconds}s)
                        </Typography>
                    </Typography>
                </DialogContent>
            </Dialog>
            {/*Email Dialog */}
            <Dialog open={emailOpen} onClose={handleEmailOpen} BackdropProps={{invisible: true}}>
                <DialogTitle sx={{padding: 0}}>
                    <DialogActions>
                        <Button onClick={handleEmailClose}>X</Button>
                    </DialogActions>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            color: 'white',
                        }}
                    >
                        Verify Email</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}
                    >
                        A 6-digit PIN number has been sent to your email.</Typography>

                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: 'white',
                            paddingTop: '5px'
                        }}
                    >
                        {user?.result?.email?.substring(0,2)}******@{user?.result?.email?.split("@")[1]}
                    </Typography>
                    <br/>
                    <Grid container spacing={1} alignItems="center">
                        {[...Array(6)].map((_, index) => (
                            <Grid item key={index}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                    value={emailCode[index]}
                                    onChange={(e) => handleChangeEmail(index, e.target.value)}
                                    inputProps={{
                                        maxLength: 1,
                                        style: {textAlign: 'center', width: '10px', height: '15px'}
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <br/>
                    <DialogActions sx={{justifyContent: 'center'}}>
                        <Button variant="contained" color="error" onClick={handleVerifyEmail}>
                            VERIFY
                        </Button>
                    </DialogActions>
                    <br/>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}>
                        Experiencing any issues?{' '}
                        <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                            Contact Customer Support
                        </Link>
                    </Typography>
                    <br/>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'white'
                        }}
                    >
                        Didn't receive the PIN number?{' '}
                        <Typography
                            component="span"
                            sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                            onClick={seconds === 0 ? handleEmailOpen() : null}
                        >
                            Resend code ({seconds}s)
                        </Typography>
                    </Typography>
                </DialogContent>
            </Dialog>

        </Container>
    );
};

export default SecurityCenter;
