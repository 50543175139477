import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {Container, Typography, Grid, Button, Box, useTheme, InputAdornment, IconButton, TextField} from "@mui/material";
import FormTextField from "../form/FormTextField";
import FormFileInput from "../form/FormFileInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Constant from "../../features/constant";
import { setAlert } from "../../features/slices/alertSlice";
import { useDispatch } from "react-redux";

const IdVerification = ({ useVerifyIdentityMutation }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();
    const [uploadedImage1, setUploadedImage1] = useState(null);
    const [uploadedImage2, setUploadedImage2] = useState(null);
    const PATH = process.env.PUBLIC_URL;
    const dispatch = useDispatch();

    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedImage1(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileChange2 = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedImage2(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage1 = () => {
        setUploadedImage1(null);
    };

    const handleRemoveImage2 = () => {
        setUploadedImage2(null);
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(5);

    const [verifyIdentity, { data, isLoading, isError, isSuccess, error }] =
        useVerifyIdentityMutation();
    
    const submit = async (data) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("idNumber", data.idNumber);
        formData.append("dateOfBirth", data.dateOfBirth);
        formData.append("idFile1", data.idFile1[0]);
        formData.append("idFile2", data.idFile2[0]);
        
        await verifyIdentity({ identity: formData }).unwrap();
        if (!isDisabled) {
            setTimer(5);
            setIsDisabled(true);
        }
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
          dispatch(
            setAlert({
              isOpen: true,
              message: t("walletcard.idverificationpage.successmessage"),
              variant: "success",
            })
          );
          
          navigate(Constant.getPath("/profileWallet"), {
                state: {
                screen: "my account",
                },
            });
        }
        if (isError) {
          dispatch(
            setAlert({
              isOpen: true,
              message: error.data.message,
              variant: "error",
            })
          );
        }
      }, [isLoading, isSuccess, isError]);

    const identitySchema = yup.object().shape({
        name: yup.string().required(t("validation.namerequired")),
        idNumber: yup.string().required(t("validation.idnumberrequired")),
        dateOfBirth: yup.string().required(t("validation.dateofbirthrequired")),
        idFile1: yup.mixed().test("file", t("validation.idFile1"), (value) => {
            if (value?.length > 0) {
              return true;
            }
            return false;
          }),
          idFile2: yup.mixed().test("file", t("validation.idFile2"), (value) => {
            if (value?.length > 0) {
              return true;
            }
            return false;
          })
    });

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(identitySchema),
    });

    return (
        <Container>
            <Typography fontWeight="bold" color="white">
                {t("walletcard.idverificationpage.title")}
            </Typography>
            <br/>
            <form onSubmit={handleSubmit(submit)}>
                <Grid container spacing={2}>
                    {/* Name ID field */}
                    <Grid item xs={12}>
                        <FormTextField
                            controllerName="name"
                            control={control}
                            fullWidth
                            rules={{ required: t("validation.namerequired")}}
                            label="Name ID"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true, // Hide placeholder text "DD/MM/YYYY"
                            }}
                            // Add any necessary props for validation, onChange handlers, etc.
                        />
                    </Grid>

                    {/* Number ID field */}
                    <Grid item xs={12}>
                        <FormTextField
                            fullWidth
                            controllerName="idNumber"
                            control={control}
                            rules={{ required: t("validation.idnumberrequired")}}
                            label="Number ID"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true, // Hide placeholder text "DD/MM/YYYY"
                            }}
                            // Add any necessary props for validation, onChange handlers, etc.
                        />
                    </Grid>

                    {/* Date of Birth field */}
                    <Grid item xs={12}>
                        <FormTextField
                            fullWidth
                            controllerName="dateOfBirth"
                            control={control}
                            rules={{ required: t("validation.dateofbirthrequired")}}
                            label="Date of Birth"
                            type="date"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true, // Hide placeholder text "DD/MM/YYYY"
                            }}
                            // Add any necessary props for validation, onChange handlers, etc.
                        />
                    </Grid>

                    {/* Upload ID field */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1"
                        sx={{
                            color:'white',
                            fontSize:'12px',
                            marginBottom: '20px'
                        }}
                        >{t("walletcard.idverificationpage.uploadid")}</Typography>
                        {/* Two upload components in the same row */}
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                sx={{
                                    // borderColor: '#000000',
                                    // borderRadius: '5px',
                                    // border: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    //padding: 2, // Add some padding to center content better
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#EDEDED',
                                        width: '400px', // Adjust the width as needed
                                        height: '150px', // Adjust the height as needed
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        position: 'relative', // Added position relative
                                        maxWidth: '100%',
                                        overflow: 'hidden', // Ensures that the image does not overflow the box
                                    }}
                                >
                                    {uploadedImage1 ? (
                                        <>
                                            <img
                                                src={uploadedImage1}
                                                alt="Uploaded"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    zIndex: 0
                                                }}
                                            />
                                            <button
                                                onClick={handleRemoveImage1}
                                                style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}
                                            >
                                                {t("walletcard.idverificationpage.removefile")}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={`${PATH}/assets/img/wallet/wallet_card/Deposit.png`}
                                                alt="Upload"
                                                style={{ width: '50px', height: '50px', zIndex: 0 }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    color: 'grey'
                                                }}
                                            >
                                                {t("walletcard.idverificationpage.filefront")}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '400px',
                                        height: '50px',
                                    }}
                                >
                                    <FormFileInput
                                        control={control}
                                        controllerName="idFile1"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="file"
                                        onChange={handleFileChange1} // Handle file change event
                                        style={{ width: '300px', height: '40px' }} // Adjust width and height as needed
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                sx={{
                                    // borderColor: '#000000',
                                    // borderRadius: '5px',
                                    // border: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    //padding: 2, // Add some padding to center content better
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#EDEDED',
                                        width: '400px', // Adjust the width as needed
                                        height: '150px', // Adjust the height as needed
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        position: 'relative', // Added position relative
                                        maxWidth: '100%',
                                        overflow: 'hidden', // Ensures that the image does not overflow the box
                                    }}
                                >
                                    {uploadedImage2 ? (
                                        <>
                                            <img
                                                src={uploadedImage2}
                                                alt="Uploaded"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    zIndex: 0
                                                }}
                                            />
                                            <button onClick={handleRemoveImage2} style={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}>
                                            {t("walletcard.idverificationpage.removefile")}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <img src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`} alt="Upload" style={{ width: '50px', height: '50px', zIndex: 0 }}/>
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    color: 'grey'
                                                }}
                                            >
                                                {t("walletcard.idverificationpage.fileselfie")}
                                            </Typography>
                                        </>
                                    )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '400px',
                                            height: '50px',
                                        }}
                                    >
                                    <FormFileInput
                                        control={control}
                                        controllerName="idFile2"
                                        defaultValue=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        id="idUpload2"
                                        type="file"
                                        onChange={handleFileChange2} // Handle file change event
                                        style={{ width: '300px', height: '40px' }} // Adjust width and height as needed
                                    />
                                    </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Submit button */}
                    <Grid item xs={12} sx={{paddingBottom:'10px', marginTop:'20px'}}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{width:'200px',height:'46px'}}
                                type="submit"
                                disabled={isDisabled}
                                //onClick={handleClick}
                                startIcon={isDisabled && <CircularProgress size={20} />}>
                                <Typography sx={{fontWeight:'bold',fontSize:'14px'}}>
                                {t("walletcard.idverificationpage.submit")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid item xs={12} sx={{backgroundColor:'lightgrey', borderRadius:'5px'}}>
                        <Grid item xs={12}>
                            <Typography sx={{fontWeight: 'bold', fontSize: '12px', color: 'red'}}>
                            {t("walletcard.idverificationpage.notetitle")}
                            </Typography>
                            <ol style={{paddingLeft: '20px'}}>
                                <li>
                                    <Typography sx={{fontSize: '12px'}}>
                                        {t("walletcard.idverificationpage.noteone")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{fontSize: '12px'}}>
                                        {t("walletcard.idverificationpage.notetwo")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{fontSize: '12px'}}>
                                    {t("walletcard.idverificationpage.notethree")}
                                    </Typography>
                                </li>
                            </ol>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1"
                                    sx={{
                                        color:'white',
                                        fontSize:'12px'
                                    }}
                        >{t("walletcard.idverificationpage.example")}</Typography>
                        {/* Two upload components in the same row */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                {/* First upload component */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative', // Added position relative
                                        maxWidth:'100%',
                                    }}
                                >
                                    <img src={PATH + `/assets/img/kyc_1.jpg`} alt="Upload" style={{ width: '358px', height: '150px', zIndex: 0, borderRadius: '5px'}} />
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'white',
                                            fontWeight:'bold'
                                        }}
                                    >
                                        {t("walletcard.idverificationpage.filefront")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* First upload component */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative', // Added position relative
                                        maxWidth:'100%',
                                    }}
                                >
                                    <img src={PATH + `/assets/img/kyc_2.jpg`} alt="Upload" style={{ width: '358px', height: '150px', zIndex: 0, borderRadius: '5px' }} />
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'white',
                                            fontWeight:'bold'
                                        }}
                                    >
                                        {t("walletcard.idverificationpage.fileselfie")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
        </Container>
    );
};

export default IdVerification;
