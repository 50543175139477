import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import en from "./en.json";
import cn from "./cn.json";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("language") || "bd",
    fallbackLng: "bd",
    // resources: {
    //   en: { translation: en },
    //   cn: { translation: cn },
    // },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATION_FILE}{{lng}}.json`,
    },
  });

export default i18n;
